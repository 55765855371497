export default {
    message: {
        contact: '联系我们',
        title: '如法币般使用加密货币',
        titleOne: '用加密货币',
        accept: '在你的网站上接受加密货币',
        pass: '通过JKpay 开始您的加密货币支付之旅,快捷、无缝、安全。',
        JKPay: 'JKPay 支付聚合多链多钱包多币种，无需KYC，让 web3.0 支付更简单，助您解锁新市场，即时转换接受任何加密货币，让您的收付款尽可能简单和安全。',
        use: '随时随地使用',
        register: '申请注册',
        downloadOne: '下载APsP或通过h5页面轻松完成注册，只需要一个邮箱即可。',
        complete: '完成KYC',
        afterCompletion: '完成注册后，按照指示一步一步完成流程，只需2分钟系统自动完成，这再简单不过了。',
        easy: '方便使用',
        obtain: '获取卡片后，充值，消费，APP可以详细查看每一笔充值、消费记录，让你随时掌握，管理您的财务状况。',
        Virtual: 'JKpay 虚拟卡',
        select: '选择 要申请的卡片等级类型，提交身份验证信息,包括姓名、身份证或护照等个人信息。',
        details: '约5分钟内就可以在卡片详情页面看到16位卡号等虚拟卡信息。',
        purchases: '使用 JKpay 将你的加密货币转化为日常购买。',
        feesOne: '每笔交易都清晰呈现，让您一目了然所有费用完全透明，无任何隐藏费用。',
        cryptocurrencies: '轻而易举储存管理您的资金和加密货币',
        Manage: '通过我们的应用程序管理您的资产，并随时使用您的卡片付款。',
        buy: '轻松买卖加密货币',
        has: 'APP内置兑换系统，让您轻松完成加密货币和法币的兑换。',
        one: '一站式加密货币支付解决方案',
        everyone: '适用于每个人、任何地方的可访问加密货币钱包。让我们通过JKpay一起拥抱未来的加密货币支付！',
        secure: '快捷 ·  安全 · 无缝',
        daily: '像法定货币一样使用加密货币，通过JKpay将您的加密货币用于日常消费。',
        problem: '常见问题',
        why: '为什么我的 KYC 验证未获批准?',
        answer: '提交身份信息后，请耐心等待，KYC审核过程通常需要5-10分钟左右，若超过审核时间也有以下几种可能。',
        answerOnes: '提供信息不完整或不准确。',
        answerTwos: '身份证明文件不符合要求。',
        answerThrees: '风险评估不通过。',
        answerFour: '部分国家反洗钱和反恐政策要求。',
        whyTwo: '如何注册JKpay账户？',
        download: '用户可从Google Play或APP Store下载JKpay  APP，然后点击“注册/登录”，选择“注册”并输入有效的电子邮件地址来注册JKPay账户。',
        answerFive: '如何开通JKpay虚拟卡？',
        fill: '在App内填写并提交身份验证信息,包括姓名、身份证或护照等个人信息。',
        before: '首次申请卡前,需要向JKpay账户提前充值至少20美元作为开卡保证金。',
        fee: '进入App的“Cards”页面,点击“立即开卡”按钮，选择卡片等级，支付开卡费用。',
        see: '约5分钟内就可以在卡片详情页面看到16位卡号等虚拟卡信息。',
        we: '我们致力于为客户提供便利和安全，',
        weOne: '确保顺畅安全的支付体验。',
        copyright: '版权所有 © 2024 JKPay 保留所有权利',
        Personal: '个人消费卡',
        wallet: '商家钱包收款',
        Fast: '快速·方便·安全',
        Monitor: '监听钱包',
        MonitorTitle: '商户通过API提交收款钱包，平台监听该钱包的收款信息，实现订单的自动确认，可直接转账支付或二维码收款。',
        Product: '产品介绍',
        Platform: '平台代收',
        MonitorOrders: '监听订单',
        PlatformAgent: '平台代付',
        PlatformTitle: '商户请求API创建订单，平台自动分配代收钱包，收款后自动确认订单，无需提供收款钱包，支持多公链多币种，任意手机钱包支付，资金自动归集，可跨链提币，定制化收银台最便捷的支付接入方式。',
        MonitorOrdersTitle: '商户请求API创建订单，平台监听订单收款钱包的收款信息实现订单的自动确认，订单支付唯一，适合多场景支付(如:同一钱包，通过订单区分充值或购买)。',
        PlatformAgentTitle: '商户通过平台代付，可自定义付款到任意钱包地址，超低手续费低，支持多公链多币种，实时转出，快速到账。',
        SecurePayment: '安全支付 · 提现秒到',
        oneStop: '一站式多币种支付，让客户无忧消费',
        Anonymous: '匿名支付',
        AnonymousTitle: '不会留存任何个人信息，无法被追踪的安全加密支付膜，保护每一位用户的转账隐私。',
        Customized: '定制收银台',
        CustomizedTitle: '免费入驻，我们提供了强大的可定制收银台，支持多公链多币种多钱包支付。',
        rate: '超低费率',
        rateTitle: '根据您的需求，我们提供多种支付模式，手续费最低可至0.6%。',
        Quick: '快速提现',
        Pick: '随时随地想提就提，无需等待，完美解决交易滞后问题。',
        pricing: '公平定价',
        pricingTitle: '通过连接和配置我们的API，你开始自动接受和支付加密货币的过程，这不需要你的干预，而是由JKPay的自动程序完成。',
        fees: '没有月费、安装费或隐藏费用',
        turnover: '针对营业额较高的企业的个人计划',
        payments: '为您提供最安全、最可靠的接收和发送付款方式',
        volume: '适合任何规模和业务的企业，无论销售额如何',
        USDT: '接受USDT，速度快如闪电',
        Network: '闪电网络可以实现可扩展的交易且费用低廉。它旨在改善交易的成本和可扩展性。商家及其客户可以进行“链下”交易，而不会堵塞主区块链，同时仍可获得支付相同的安全性和便利性。',
        LowerCost: '费用更低 -- ',
        cent: '客户支付的交易费不到几分之一美分',
        Large: '大国膜可扩展 -- ',
        small: '接受频繁付款和小额交易',
        Attracting: '吸引新受众 -- ',
        required: '无需额外设置',
        Eliminate: '消除欺诈退款 -- ',
        Cryptocurrency: '加密支付不可逆转',
        FreeEntry: '免费入驻，一键生成您的专属收银台。',
        Entering: '进入价值1万亿美元的加密货币市场',
        accepts: 'JKPay 接受 100 多种加密货币，占全球加密货币市值的 90%以上。',
        support: '我们支持的所有加密钱包',
        supported: '以下是 JKPay 支持的一些领先钱包和交易所，像发送电子邮件一样快速、轻松的发送USDT发票。',
        what: 'JKPay聚合支付是什么？',
        answerOne: 'JKPay支付系统聚合了所有主流的加密数字货币、主流公链以及主流钱包，让您的收付款尽可能简单，给用户更好的支付体验。',
        whatTwo: 'JKPay支持哪些数字货币?',
        answerTwo: '我们支持所有主流公链和数字货币，您可以通过商家控制台配置您需要展示的数字货币到您的收银台，如需更多代币，也可以与我们进行沟通。',
        whatThree:'我们的支付和其他支付产品相比有什么优势？',
        answerThree:'JKPay支持所有主流公链、数字货币和数字钱包，更为商家免费提供可定制化收银台，我们还提供多种收款模式，手续费最低可至0.6%,商家可根据自身业务需求进行接入。',
    },    
  }
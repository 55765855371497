export default {
    message: {
        contact: 'Contact Us',
        title: 'SPEND CRYPTO LIKE FIAT',
        // title: 'Spend Crypto Like Fiat',
        pass: 'Start your cryptocurrency payment journey with JKPay, fast, seamless, and secure.',
        JKPay: 'JKPay payment aggregates multiple chains, wallets, and currencies without the need for KYC, making web3.0 payments easier and helping you unlock new markets. It instantly converts and accepts any cryptocurrency, making your payments as simple and secure as possible.',
        use: 'Use anytime anywhere',
        register: 'Apply for registration',
        accept: 'Accept Crypto on Your Website',
        // accept: 'ACCEPT CRYPTO ON YOUR WEBSITE',
        downloadOne: 'Download the app or easily register through the H5 page with just one email.',
        complete: 'Complete KYC',
        afterCompletion: 'After completing the registration, follow the instructions to complete the process step by step. It only takes 2 minutes for the system to complete automatically.',
        easy: 'Easy to Use',
        obtain: 'After obtaining the card, recharge and consume, the APP can view each recharge and consumption record in detail, so that you can grasp and manage your financial situation at any time.',
        Virtual: 'JKPay Virtual Card',
        select: 'Select the type of card level to apply for and submit identity verification information, including personal information such as name, ID card, or passport.',
        details: 'You can see virtual card information such as a 16 digit card number on the card details page within about 5 minutes.',
        purchases: 'Use JKPay to convert your cryptocurrency into daily purchases.',
        feesOne: 'Each transaction is presented clearly, making all fees completely transparent and without any hidden fees.',
        cryptocurrencies: 'Easily store and manage your funds and cryptocurrencies',
        Manage: 'Manage your assets through our application and make payments with your card at any time.',
        buy: 'Easily buy and sell cryptocurrencies',
        has: 'The APP has a built-in exchange system, allowing you to easily exchange cryptocurrencies and fiat currencies.',
        one: 'One stop cryptocurrency payment solution',
        everyone: "An accessible cryptocurrency wallet suitable for everyone and anywhere.Let's embrace the future of cryptocurrency payments together through JKPay!",
        secure: 'Quick ·  Secure · Seamless',
        daily: 'Use cryptocurrency like legal tender and use it for daily consumption through JKPay.',
        problem: 'Common Problem',
        why: 'Why was my KYC verification not approved?',
        answer: "After submitting your identity information, please be patient. The KYC review process usually takes about 5-10 minutes, and there are several possibilities if it exceeds the review time.",
        answerOnes: 'The information provided is incomplete or inaccurate.',
        answerTwos: 'The identification documents do not meet the requirements.',
        answerThrees: 'The risk assessment did not pass.',
        answerFour: 'Some countries require anti money laundering and counter-terrorism policies.',
        whyTwo: 'How to register a JKPay account?',
        download: 'Users can download the JKPay app from Google Play or the App Store, then click on "Register/Login", select "Register" and enter a valid email address to register for a JKPay account.',
        answerFive: 'How to activate JKPay virtual card?',
        fill: 'Fill in and submit identity verification information within the app, including personal information such as name, ID card, or passport.',
        before: 'Before applying for a card for the first time, it is necessary to prepay at least $20 to the JKPay account as a deposit for opening the card.',
        fee: 'Go to the "Cards" page of the app, click the "Open Card Now" button, select the card level, and pay the card opening fee.',
        see: 'You can see virtual card information such as a 16 digit card number on the card details page within about 5 minutes.',
        we: 'We are committed to providing convenience and security to our customers, ',
        weOne: 'ensuring a smooth and secure payment experience.',
        copyright: 'copyright ©  2024 JKPay reserves all rights',
        Personal: 'Personal Consumption Card',
        wallet: 'Merchant Wallet Collection',
        Fast: 'Fast·Convenient·Safe',
        Monitor: 'Monitor wallet',
        Product: 'Product Introduction',
        Platform: 'System Wallet',
        MonitorOrders: 'Monitor orders',
        PlatformAgent: 'Platform pay another',
        MonitorTitle: 'Merchants submit the collection wallet through the API, the platform listens to the collection information, the order will be automatically confirmed, this way to support the transfer payment or QR code collection.',
        PlatformTitle: 'Merchants request API to create an order, the platform automatically assigns the collection wallet, automatically confirms the order after collection, no need to provide the collection wallet, supports multi-public chain multi-currency, any cell phone wallet payment, the funds are automatically pooled, cross-chain withdrawals are available, customized checkout counter.',
        MonitorOrdersTitle: 'Merchants request API to create orders, the platform listens for collection information, the order is automatically confirmed, and the order payment is unique, suitable for multi-scenario payment (e.g., the same wallet, differentiating between recharging or purchasing through orders).',
        PlatformAgentTitle: 'Merchants pay on behalf of the payouts, you can customize the payouts to any wallet address, ultra-low fees low, support multiple public chain multi-currency, real-time transfer, fast to the account.',
        SecurePayment: 'Safe payment, quick arrival',
        oneStop: 'One-stop and multi-currency payment, customers without worry consumption.',
        Anonymous: 'Anonymous payment',
        AnonymousTitle: "No personal information will be retained, and secure encrypted payments that cannot be traced. Protect the privacy of each user's transfer.",
        Customized: 'Customized cash register',
        CustomizedTitle: 'It is free to settle in, and we provide a powerful and customizable cash register that supports multi-chain, multi-currency, and multi-wallet payments.',
        rate: 'Ultra low rates',
        rateTitle: 'According to your needs, we provide a variety of payment methods, and the lowest handling fee can be as low as 0.6%.',
        Quick: 'Quick withdrawal',
        Pick: 'You can withdraw it any time any where, without waiting, which perfectly solves the problem of transaction lag.',
        pricing: 'Fair pricing',
        pricingTitle: "By connecting and configuring our API, you begin the process of automatically accepting and paying for cryptocurrency, which does not require your intervention but is completed by JKPay's automated program.",
        fees: 'No monthly, setup or hidden fees',
        turnover: 'Personal plans for businesses with a higher turnover rate',
        payments: 'We provide you with the safest and most reliable payment methods for receiving and sending payments',
        volume : 'It fits businesses of any size and line, regardless of sales',
        USDT: 'Accept USDT,Lightning fast.',
        Network: 'The Lightning Network enables scalable transactions at a low cost. It aims to improve the cost and scalability of transactions. Merchants and their customers can conduct "off chain" transactions without blocking the main blockchain, while still obtaining the same security and convenience as payments.',
        LowerCost: 'CHEAPER FEES  -- ',
        cent: 'Customers pay less than a fraction of a cent in transaction fees',

        Large: 'MASSIVELY SCALABLE -- ',
        small: 'Accept frequent payments and microtransactions',
        Attracting: 'EACH A NEW AUDIENCE -- ',
        required: 'No additional setup required',
        Eliminate: 'ELIMINATE FRAUD CHARGEBACKS -- ',
        Cryptocurrency: 'Crypto payments are irreversible',
        FreeEntry: 'Free entry, one click generation of your exclusive checkout counter.',
        Entering: 'Gain access to a $1+ trillion crypto market',
        accepts: 'JKPay accepts 100+ cryptocurrencies which account for over 90% of the global crypto market cap.',

        support: 'We support all crypto wallets ',
        supported: 'Here are some of the leading wallets and exchanges JKPay supports. Send USDT invoices as quickly and easily as sending an email.',
        what: 'What is JKPay aggregated payment?',
        answerOne: 'The JKPay payment system aggregates all mainstream cryptocurrencies, public blockchains, and wallets, making your payments as simple as possible and providing users with a better payment experience.',	
        whatTwo: 'What digital currencies does JKPay support?',
        answerTwo:'We support all mainstream public chains and digital currencies. You can configure the digital currencies you need to display to your checkout through the merchant console. If you need more tokens, you can also communicate with us.',
        whatThree:'What are the advantages of our payment compared to other payment products?',
        answerThree:'JKPay supports all mainstream public chains, digital currencies, and digital wallets, and provides merchants with customizable cash registers for free. We also offer multiple payment modes with a minimum transaction fee of 0.6%, and merchants can access them according to their own business needs.',
    },    

  }
import { createI18n } from "vue-i18n";
import en from './en-US'
import zh from './zh-TW';
import cn from './zh-CN';

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'en-US', // 默认英文
  fallbackLocale: 'en-US',
  globalInjection: true,//全局配置$t
  legacy: false, // 遗留模式
  // messages:{en,zh,cn}// 需要做国际化的语种
  messages: {
    'en-US': en,
    'zh-TW': zh,
    'zh-CN': cn
  }
})

export default i18n 
import {createRouter, Router, createWebHistory, RouteRecordRaw} from 'vue-router';
import i18n from '@/lang/i18n1'
const { locale } = i18n.global
const allowedLanguages = ['en-US', 'zh-CN', 'zh-TW']
import NotFoundView from '@/views/NotFoundView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("../views/HomeView.vue"),
    meta: {title: "JKPay"},
  },
  {
    path: '/:lang?/',
    name: 'lang',
    component: () => import('../components/HelloWorld.vue'),
    meta: {title: "JKPay"},
    children: [
      {
        path: 'card',
        name: 'card',
        component: () => import("../views/HomeView.vue"),
        meta: {title: "JKPay"}

      },
      {
        path: 'wallet',
        name: 'wallet',
        component: () => import("../views/AboutView.vue"),
        meta: {title: "JKPay"}
      },
      {
        path: 'phoneHome',
        component: () => import("../views/phoneHome.vue"),
        meta: {title: "JKPay"}
      },
      {
        path: 'phoneWallet',
        component: () => import("../views/phoneWallet.vue"),
        meta: {title: "JKPay"}
      },
    ]
  },
  {
    path: '/404',
    component: NotFoundView
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/404'
  }
];
const router: Router = createRouter({
  history: createWebHistory('/'),
  routes,
})


// 判断是否进入错误语言路径，如果错误跳转404页面
// router.beforeEach((to, from, next) => {
//   to.meta.title && (document.title = to.meta.title as string)
//   const lang = to.params.lang as "en-US" | "zh-CN" | "zh-TW"
//   if (lang) {
//     if (!allowedLanguages.includes(lang)) {
//       next({ name: '404'})
//       return
//     } else {
//       locale.value = lang
//       localStorage.setItem('language', lang)
//     }
//   }
//   next()
// })

export default router
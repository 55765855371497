export default {
    message: {
        contact: '聯繫我們',
        title: '如法幣般使用加密貨幣',
        titleOne: '用加密貨幣',
        accept: '在你的網站上接受加密貨幣',
        pass: '通過JKpay開始您的加密貨幣支付之旅，快捷、無縫、安全。',
        JKPay: 'JKPay支付聚合多鏈多錢包多幣種，無需KYC，讓web3.0支付更簡單，助您解鎖新市場，即時轉換接受任何加密貨幣，讓您的收付款盡可能簡單和安全。',
        use: '隨時隨地使用',
        register: '申請註冊',
        downloadOne: '下載APP或通過h5頁面輕鬆完成注册，只需要一個郵箱即可。',
        complete: '完成KYC',
        afterCompletion: '完成注册後，按照訓示一步一步完成流程，只需2分鐘系統自動完成，這再簡單不過了。',
        easy: '方便使用',
        obtain: '獲取卡片後，充值，消費，APP可以詳細查看每一筆充值、消費記錄，讓你隨時掌握，管理您的財務狀況。',
        Virtual: 'JKpay虛擬卡',
        select: '選擇要申請的卡片等級類型，提交身份驗證資訊，包括姓名、身份證或護照等個人資訊。',
        details: '約5分鐘內就可以在卡片詳情頁面看到16位卡號等虛擬卡信息。',
        purchases: '使用JKpay將你的加密貨幣轉化為日常購買。',
        feesOne: '每筆交易都清晰呈現，讓您一目了然所有費用完全透明，無任何隱藏費用。',
        cryptocurrencies: '輕而易舉儲存管理您的資金和加密貨幣',
        Manage: '通過我們的應用程序管理您的資產，並隨時使用您的卡片付款。',
        buy: '輕鬆買賣加密貨幣',
        has: 'APP內寘兌換系統，讓您輕鬆完成加密貨幣和法幣的兌換。',
        one: '一站式加密貨幣支付解決方案',
        everyone: '適用於每個人、任何地方的可訪問加密貨幣錢包。讓我們通過JKpay一起擁抱未來的加密貨幣支付！',
        secure: '快捷 ·  安全 · 無縫',
        daily: '像法定貨幣一樣使用加密貨幣，通過JKpay將您的加密貨幣用於日常消費。',
        problem: '常見問題',
        why: '為什麼我的KYC驗證未獲準予？',
        answer: '提交身份資訊後，請耐心等待，KYC稽核過程通常需要5-10分鐘左右，若超過稽核時間也有以下幾種可能。',
        answerOnes: '提供資訊不完整或不準確。',
        answerTwos: '身份證明檔案不符合要求。',
        answerThrees: '風險評估不通過。',
        answerFour: '部分國家反洗錢和反恐政策要求。',
        whyTwo: '如何注册JKpay帳戶？',
        download: '用戶可從Google Play或APP Store下載JKpay APP，然後點擊“注册/登入”，選擇“注册”並輸入有效的電子郵寄地址來注册JKPay帳戶。',
        answerFive: '如何開通JKpay虛擬卡？',
        fill: '在App內填寫並提交身份驗證資訊，包括姓名、身份證或護照等個人資訊。',
        before: '首次申請卡前，需要向JKpay帳戶提前充值至少20美元作為開卡保證金。',
        fee: '進入App的“Cards”頁面，點擊“立即開卡”按鈕，選擇卡片等級，支付開卡費用。',
        see: '約5分鐘內就可以在卡片詳情頁面看到16比特卡號等虛擬卡資訊。',
        we: '我們致力於為客戶提供便利和安全，',
        weOne: '確保順暢安全的支付體驗。',
        copyright: '版權所有 ©  2024 JKPay保留所有權利',
        Personal: '個人消費卡',
        wallet: '商家收款錢包',
        Fast: '快速·方便·安全',
        Monitor: '監聽錢包',
        MonitorTitle: '商戶通過API提交收款錢包，平臺監聽該錢包的收款資訊，實現訂單的自動確認，可直接轉帳支付或二維碼收款。',
        Product: '產品介紹',
        Platform: '平臺代收',
        MonitorOrders: '監聽訂單',
        PlatformAgent: '平臺代付',
        PlatformTitle: '商戶請求API創建訂單，平臺自動分配代收錢包，收款後自動確認訂單，無需提供收款錢包，支持多公鏈多幣種，任意手機錢包支付，資金自動歸集，可跨鏈提幣，定制化收銀台最便捷的支付接入管道。',
        MonitorOrdersTitle: '商戶請求API創建訂單，平臺監聽訂單收款錢包的收款資訊實現訂單的自動確認，訂單支付唯一，適合多場景支付（如：同一錢包，通過訂單區分充值或購買）。',
        PlatformAgentTitle: '商戶通過平臺代付，可自定義付款到任意錢包地址，超低手續費低，支持多公鏈多幣種，實时轉出，快速到賬。',
        SecurePayment: '安全支付·提現秒到',
        oneStop: '一站式多幣種支付，讓客戶無憂消費',
        Anonymous: '匿名支付',
        AnonymousTitle: '不會留存任何個人資訊，無法被追跡的安全加密支付膜，保護每一位用戶的轉帳隱私。',
        Customized: '定制收銀台',
        CustomizedTitle: '免費入駐，我們提供了强大的可定制收銀台，支持多公鏈多幣種多錢包支付。',
        rate: '超低費率',
        rateTitle: '根據您的需求，我們提供多種支付模式，手續費最低可至0.6%。',
        Quick: '快速提現',
        Pick:'隨時隨地想提就提，無需等待，完美解决交易滯後問題。',
        pricing: '公平定價',
        pricingTitle: '通過連接和配置我們的API，你開始自動接受和支付加密貨幣的過程，這不需要你的干預，而是由JKPay的自動程序完成。',
        fees: '沒有月費、安裝費或隱藏費用。',
        turnover: '針對營業額較高的企業的個人計畫',
        payments: '為您提供最安全、最可靠的接收和發送付款方式',
        volume: '適合任何規模和業務的企業，無論銷售額如何',
        Network:'閃電網絡可以實現可擴展的交易且費用低廉。它旨在改善交易的成本和可擴展性。商家及其客戶可以進行「鏈下」交易，而不會堵塞主區塊鏈，同時仍可獲得支付相同的安全性和便利性。',
        USDT: '接受USDT，速度快如閃電',
        Networks: '閃電網絡可以實現可擴展的交易且費用低廉。 它旨在改善交易的成本和可擴展性。 商家及其客戶可以進行“鏈下”交易，而不會堵塞主區塊鏈，同時仍可獲得支付相同的安全性和便利性。',
        LowerCost: '費用更低 -- ',
        cent: '客戶支付的交易費不到幾分之一美分',
        Large: '大國膜可擴展 -- ',
        small: '接受頻繁付款和小額交易',
        Attracting: '吸引新受眾 -- ' ,
        required: '無需額外設定',
        Eliminate: '消除欺詐退款 -- ',
        Cryptocurrency: '加密支付不可逆轉',
        FreeEntry: '免費入駐，一鍵生成您的專屬收銀台。',
        Entering: '進入價值1萬億美元的加密貨幣市場',
        accepts:'JKPay接受100多種加密貨幣，占全球加密貨幣市值的90%以上。',
        support:'我們支持的所有加密錢包',
        supported:'以下是 JKPay 支持的一些領先錢包和交易所，像發送電子郵件一樣快速、輕松的發送USDT發票。',
        what:'JKPay聚合支付是什麼？',
        answerOne:'JKPay支付系統聚合了所有主流的加密數位貨幣、主流公鏈以及主流錢包，讓您的收付款盡可能簡單，給用戶更好的支付體驗。',
        whatTwo:'JKPay支持哪些數位貨幣？',
        answerTwo:'我們支持所有主流公鏈和數位貨幣，您可以通過商家控制台配寘您需要展示的數位貨幣到您的收銀台，如需更多代幣，也可以與我們進行溝通。',
        whatThree:'我們的支付和其他支付產品相比有什麼優勢？',
        answerThree:'JKPay支持所有主流公鏈、數位貨幣和數位錢包，更為商家免費提供可定制化收銀台，我們還提供多種收款模式，手續費最低可至0.6%，商家可根據自身業務需求進行接入。',
    },    
  }
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    // 路由守卫
    const router = useRouter();
    const i18n = useI18n();
    router.beforeEach((to, from, next) => {
      // 检查to.params.locale 是否存在，并设置相应的语言环境或执行其他逻辑
      const lang = to.params.locale;
      localStorage.setItem("localeKey", lang || "en-US");
      i18n.locale.value = lang || "en-US";
      // 放这才能根据语言设title标签内容
      document.title =
        to.meta.title || "card";
      next(); // 确保要调用next方法
    });
  },
});
</script>
<style>
html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;

  margin: 0;
  padding: 0;

}

html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Ele from 'element-plus';
import 'element-plus/dist/index.css';
import i18n from './lang/i18n1'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Ele)
app.use(i18n)

app.mixin({
  created() {
    const { locale } = i18n.global
    const lang = localStorage.getItem('language') as "zh-CN" | "en-US" | "zh-TW"
    lang && (locale.value =  lang)
  },
})

app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}